import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dimmer, Loader } from 'semantic-ui-react';
import classNames from 'classnames';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useModal } from 'components/modals/ModalContext/modal.context';
import BuIcon from 'components/UI/BuIcon';
import {
  MeddicField,
  StepWithValue,
} from 'components/dashboard/Forecast/MeddicSidePanel/MeddicSidePanel';
import { ValueType } from 'components/UI/common/TypedTable/TypedTable';
import BuButton from 'components/UI/BuButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getFeatureFlags, getUserLocalCurrency } from 'selectors';
import { putSalesProcess } from 'api/CallInsights';

import * as styles from './styles';
import {
  SalesProcessModal as SalesProcessModalType,
  Step,
  Task,
} from '../../types';
import {
  getIsTaskCompleted,
  getStatusAndDateLabelForTaskModal,
} from '../../helpers';

export const SalesProcessModalTitle: React.FC = () => {
  const { getPropsForScheme } = useModal<SalesProcessModalType>();

  const { salesProcessItem } = getPropsForScheme('/sales-process') || {};

  const { name: taskName = '' } = salesProcessItem.task as Task;

  return <div className={styles.salesProcessModalTitle}>Update {taskName}</div>;
};

const SalesProcessModal: React.FC = () => {
  const queryClient = useQueryClient();
  const [updatedValues, setUpdatedValues] = useState<Step[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { getPropsForScheme, closeModal } = useModal<SalesProcessModalType>();
  const companyCurrency = useSelector(getUserLocalCurrency);
  const { task_completion_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );
  const salesProcessModalContentRef = useRef<HTMLDivElement>(null);

  const {
    salesProcessItem: salesProcessItemProps,
    callId,
    user_id,
    completeTaskMutation,
    regenerateTaskMutation,
    isRegeneratingTask,
    callTodoData,
  } = getPropsForScheme('/sales-process') || {};

  const salesProcessItem =
    callTodoData.find((item) => item.id === salesProcessItemProps.id) ||
    salesProcessItemProps;

  const {
    name: taskName = '',
    opportunity_id = '',
    steps: initialSteps = [],
    genai_enabled,
  } = (salesProcessItem?.task as Task) || {};

  useEffect(() => {
    setUpdatedValues(
      initialSteps.map((step) => ({
        ...step,
        user_changed: false,
        hasAiNewValue:
          !!step.explanation && step.new_value !== step.current_value,
      }))
    );
  }, [initialSteps]);

  useEffect(() => {
    if (salesProcessModalContentRef.current && isRegeneratingTask) {
      salesProcessModalContentRef.current.scrollTo({
        top: 0,
      });
    }
  }, [isRegeneratingTask]);

  const changeSalesProcessMutation = useMutation({
    mutationFn: ({
      steps,
    }: {
      steps: Record<string, Step['new_value']>;
      isCompleteTask: boolean;
    }) =>
      putSalesProcess({
        opportunity_id,
        sales_process: taskName,
        steps,
      }),
    onMutate: ({ isCompleteTask }) => {
      setIsSaving(true);

      toast.warn(
        isCompleteTask
          ? 'Saving sales process and completing task...'
          : 'Saving sales process...',
        { position: 'bottom-left' }
      );
    },
    onError: (_, { isCompleteTask }) => {
      setIsSaving(false);

      toast.error(
        isCompleteTask
          ? 'Error saving sales process and completing task.'
          : 'Error saving sales process.',
        { position: 'bottom-left' }
      );
    },
    onSuccess: (_, { isCompleteTask }) => {
      setIsSaving(false);

      toast.success(
        isCompleteTask
          ? 'Sales process saved successfully and task completed.'
          : 'Sales process saved successfully.',
        { position: 'bottom-left' }
      );

      closeModal('/sales-process', () => ({}));

      if (getIsSalesProcessOwner() && isCompleteTask) {
        completeTaskMutation.mutate({
          task_id: salesProcessItem.id,
          isTaskCompleted,
          noToast: true,
        });
        return;
      }

      queryClient.invalidateQueries(['callTodoData', callId]);
    },
  });

  const handleFieldChange =
    (step: Step, isUserChange: boolean = true) =>
    (value: ValueType) => {
      setUpdatedValues(
        updatedValues.map((item) =>
          item.step_object_field === step.step_object_field
            ? { ...step, new_value: value, user_changed: isUserChange }
            : item
        )
      );
    };

  const getIsSalesProcessOwner = () => salesProcessItem.owner_id === user_id;

  const isTaskCompleted = getIsTaskCompleted(salesProcessItem.status);

  const statusAndDateLabelNewValues =
    getStatusAndDateLabelForTaskModal(salesProcessItem);

  const statusAndDateLabelExistingValues = getStatusAndDateLabelForTaskModal(
    salesProcessItem,
    true
  );

  const showCompleteTaskButton =
    getIsSalesProcessOwner() && !isTaskCompleted && !!task_completion_enabled;

  const changeSalesProcess = (isCompleteTask: boolean = false) => {
    const stepsPayload = updatedValues.reduce(
      (acc: Record<string, Step['new_value']>, step: Step) => {
        acc[step.field_info.step_name] = step.new_value;
        return acc;
      },
      {} as Record<string, Step['new_value']>
    );

    changeSalesProcessMutation.mutate({
      steps: stepsPayload,
      isCompleteTask,
    });
  };

  const handleUndoClicked = (step: Step) => {
    const originalNewValue = initialSteps.find(
      (item) => item.step_object_field === step.step_object_field
    )?.new_value;

    handleFieldChange(step, false)(originalNewValue as ValueType);
  };

  const isRegenerateButtonDisabled =
    isSaving || isRegeneratingTask || !genai_enabled || isTaskCompleted;

  const regenerateButtonTooltip = isRegenerateButtonDisabled
    ? !genai_enabled
      ? 'GenAI has been disabled for this Sales Process'
      : isTaskCompleted
      ? 'Task is already completed'
      : null
    : null;

  return (
    <div className={styles.salesProcessModalWrapper}>
      <div className="sales-process-modal-title">
        Suggested {taskName} Updates. Do you want to update it?
      </div>

      <div className="sales-process-modal-ai-infomartion">
        <BuIcon name={BoostUpIcons.AiIcon} />

        <div className="sales-process-modal-ai-infomartion-text">
          {isRegeneratingTask ? (
            <div>
              <span className="sales-process-modal-ai-infomartion-text-title">
                Generating New Values
              </span>

              <span>
                This will just take a moment. We're refreshing your form with
                new content. You can close this modal, and our BoostBot will
                continue regenerating the information.
              </span>
            </div>
          ) : (
            <span>
              Based on the call transcript and previous values of the Sales
              Process fields, our BoostBot (GenAI) has auto filled some of the
              information for you. Feel free to edit any of these fields.
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames('sales-process-modal-content', {
          'sales-process-modal-content-regenerating': isRegeneratingTask,
        })}
        ref={salesProcessModalContentRef}
      >
        {isRegeneratingTask && (
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        )}

        <div className="left-side">
          <div className="left-side-title">Existing values</div>

          {!!statusAndDateLabelExistingValues && (
            <div className="last-updated-gen-ai">
              Last update on {statusAndDateLabelExistingValues}
            </div>
          )}

          <div className="left-side-fields">
            {initialSteps.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly
                step={
                  {
                    ...step.field_info,
                    editable: false,
                    value: step.current_value,
                  } as StepWithValue
                }
                companyCurrency={companyCurrency}
              />
            ))}
          </div>
        </div>

        <div className="middle-arrow">
          <BuIcon name={BoostUpIcons.ChevronRight} />
        </div>

        <div className="right-side">
          <div className="right-side-title">New values</div>

          {!!statusAndDateLabelNewValues && (
            <div className="last-updated-gen-ai">
              Last update by Gen AI on {statusAndDateLabelNewValues}
            </div>
          )}

          <div className="right-side-fields">
            {updatedValues.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly={!!isRegeneratingTask}
                step={
                  {
                    ...step.field_info,
                    editable: !isRegeneratingTask,
                    explanation: step.explanation,
                    value: step.new_value,
                    user_changed: step.user_changed,
                    hasAiNewValue: step.hasAiNewValue,
                  } as StepWithValue
                }
                onChange={handleFieldChange(step)}
                companyCurrency={companyCurrency}
                handleUndoClicked={() => handleUndoClicked(step)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="sales-process-modal-footer">
        <BuButton
          basic
          disabled={isSaving}
          onClick={() => closeModal('/sales-process', () => ({}))}
        >
          Cancel
        </BuButton>

        <div className="divider" />

        <TooltipWrapper tooltip={regenerateButtonTooltip}>
          <div>
            <BuButton
              disabled={isRegenerateButtonDisabled}
              secondary
              onClick={() =>
                regenerateTaskMutation?.mutate({ task_id: salesProcessItem.id })
              }
            >
              <BuIcon name={BoostUpIcons.AiRegenerate} /> Regenerate
            </BuButton>
          </div>
        </TooltipWrapper>

        <div className="divider" />

        <BuButton
          secondary={showCompleteTaskButton}
          onClick={() => changeSalesProcess(false)}
          disabled={isSaving || isRegeneratingTask}
        >
          Submit
        </BuButton>

        {showCompleteTaskButton && (
          <BuButton
            onClick={() => changeSalesProcess(true)}
            disabled={isSaving || isRegeneratingTask}
          >
            Submit and complete task
          </BuButton>
        )}
      </div>
    </div>
  );
};

export default SalesProcessModal;
